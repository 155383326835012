<template>
  <div>
    <el-dialog
      title="线下支付"
      :visible.sync="isShow"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div class="content">
        <p class="amount">充值金额：2000元</p>
        <div class="info">
          <p class="title">收款账户信息</p>
          <div class="group">
            <p>收款账号: xxxxxxxxxxx</p>
            <span @click="copyFn">复制</span>
          </div>
          <p>收款户名: xxxxxxxxxxx</p>
          <p>收款银行: xxxxxxxxxxx</p>
        </div>
        <el-button @click="uploadFn">确认支付</el-button>
      </div>
    </el-dialog>
    <UploadVoucher :isShow="uploadDialogIsShow" @close="closeDialog('uploadDialogIsShow')"/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      uploadDialogIsShow: false
    }
  },
  props: {
    isShow: {
      type: Boolean
    }
  },
  components: {
    UploadVoucher: ()=> import('./UploadVoucher.vue')
  },
  methods: {
    uploadFn () {
      this.uploadDialogIsShow= true
    },
    closeDialog (tag) {
      this[tag] = false
    },
    handleClose () {
      this.$emit('close')
    },
    copyFn () {
      let oInput = document.createElement('textarea')
      oInput.value = '1231213212311'
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand("Copy")
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      this.$message.success('复制成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  text-align: center;
}
.amount{
  font-size: 16px;
  color: orange;
  font-weight: bold;
  margin-bottom: 20px;
}
.info{
  width: 80%;
  margin: 0 auto;
  text-align: left;
  p{
    margin-bottom: 10px;
  }
}
.group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    color: #2084d6;
    cursor: pointer;
  }
}
.el-button{
  margin-top: 20px;
}
</style>